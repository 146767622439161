import type { Environment } from './types';
import { sharedSoftwareEnv, sharedDevEnv, sharedDevAuEnv } from './shared';

export const env: Environment = {
  ...sharedDevEnv,
  ...sharedSoftwareEnv,
  ...sharedDevAuEnv,
  adminAppUrl: 'https://dev.admin.skin.software',
  logoUrl: 'https://assets.skin.software/software-logo.svg',
};
